<template>
  <div>
    <div :style="$vuetify.breakpoint.smAndDown ? 'height: 60vh;' : 'height: 58vh;'">
      <div v-if="(currentCase && currentCase._key)|| true" style="height: 53vh;">
        <v-row class="mb-3" justify="center">
          <span
            v-if="!skeleton"
            class="white--text font-weight-bold pt-5 canary-title"
          >{{ $t('titleCase') }} {{ caseTitle }}</span>
        </v-row>
        <div
          class="container pt-0 pb-0 px-0"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 95vw' : 'width: 65vw'"
        >
          <div ref="messageHistory" class="message-history mt-0 pa-0 white">
            <div>
              <template v-if="skeleton" v-for="i in [1]">
                <v-skeleton-loader
                  :key="i"
                  ref="skeleton"
                  boilerplate
                  type="list-item-avatar-three-line"
                  class="mx-auto"
                ></v-skeleton-loader>
              </template>
              <v-card
                v-for="(message, index) in messages"
                tile
                flat
                class="py-3 px-5"
                :color="message.userKey !== null ? '#f1f1f1' : '#ffffff'"
                :key="message._id"
                :style="$vuetify.breakpoint.smAndUp ? 'border-top: 1px solid #e2e2e2' : ''"
              >
                <v-row class="px-3">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    lg="3"
                    xl="3"
                    class="py-1 px-0"
                    :style="$vuetify.breakpoint.xsOnly ? 'border-top: 1px solid #e2e2e2' : ''"
                  >
                    <v-list-item three-line>
                      <v-list-item-avatar>
                        <v-img :src="getAvatarUrl(message)" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div v-if="message.userKey === null" class="mb-5 author">
                          {{ subjectName }}
                          <br />
                          <span class="timestamp">
                            {{ displayDate(message.timestamp) }}
                            <br />
                            {{ displayTime(message.timestamp) }}
                            <br />
                          </span>
                        </div>
                        <div v-else class="mb-5 author">
                          {{ message.author }}
                          <br />
                          <span class="timestamp">
                            {{ displayDate(message.timestamp) }}
                            <br />
                            {{ displayTime(message.timestamp) }}
                            <br />
                          </span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="7" xl="7">
                    <span
                      v-if="index === (messages.length - 1)"
                      class="primary--text font-weight-medium"
                    >{{ messageCreated }}</span>
                    <span
                      :class="[message.userKey === null ? 'primary--text' : '', 'preserve message']"
                    >{{ message.message }}</span>
                    <div
                      v-if="message.attachments && message.attachments.length > 0"
                      class="message primary--text mt-3"
                    >
                      <span class="font-weight-medium">{{ $t('labelAttachments') }}</span>
                      <v-spacer />
                      <span v-for="attachment in message.attachments" class="mr-2">
                        <v-icon
                          :color="(!attachment.virusScanResult || attachment.virusScanResult.status === 'Clean') ? 'primary' : 'red lighten-2'"
                        >attachment</v-icon>
                        <a
                          v-if="attachment.complete && (!attachment.virusScanResult || attachment.virusScanResult.status === 'Clean')"
                          href="javascript:void(0)"
                          @click="downloadAttachment(message, attachment)"
                        >{{attachment.fileName}}</a>
                        <span
                            v-if="!attachment.complete"
                        >{{attachment.fileName}} (Upload failed)</span>
                        <span
                          v-if="attachment.virusScanResult && attachment.virusScanResult.status !== 'Clean'"
                          class="red--text"
                        >{{attachment.fileName}} (Virus detected)</span>
                      </span>
                    </div>
                  </v-col>
                  <v-col v-if="index === (messages.length - 1)" cols="2" align="right">
                    <v-btn
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      outlined
                      color="primary"
                      @click="caseDetailsDialog = !caseDetailsDialog"
                    >{{ $t('labelViewCaseDetails') }}</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container px-0 pt-0"
      :style="$vuetify.breakpoint.smAndDown ? 'width: 95vw' : 'width: 65vw'"
    >
      <v-form ref="replyForm">
        <v-card class="pb-3 px-2" flat tile>
          <v-card-text>
            <v-row class="px-3">
              <v-col cols="12" class="pb-0 mb-0">
                <v-row>
                  <v-avatar size="32" class="mx-3">
                    <v-img :src="getUserAvatar(self._key)" />
                  </v-avatar>
                  <v-textarea
                    no-resize
                    v-model="replyMessage.message"
                    outlined
                    rows="5"
                    hide-details
                    :rules="messageRules"
                    :disabled="sending"
                  ></v-textarea>
                </v-row>
              </v-col>
              <v-col cols="5" class="pb-0 mb-0">
                <v-row class="justify-end">
                  <v-col class="d-flex fill-height">
                    <v-btn
                      absolute
                      depressed
                      style="bottom: 15px; right: 40px"
                      :x-small="$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.mdAndUp"
                      :loading="sending"
                      :disabled="sending"
                      color="primary"
                      @click="sendMessage"
                    >{{ $t('labelSendMessage') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </div>
    <v-dialog v-model="caseDetailsDialog" max-width="750">
      <case-details-dialog
        :currentCase="currentCase"
        @close="caseDetailsDialog = !caseDetailsDialog"
      ></case-details-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import CaseDetailsDialog from "@/components/CaseDetailsDialog.vue";
import moment from "moment";

export default {
  name: "Setup",
  components: {
    CaseDetailsDialog,
  },
  data: () => ({
    skeleton: false,
    search: null,
    caseKey: null,
    caseDetailsDialog: false,
    replyDialog: false,
    currentCase: {},
    messages: [],
    replyMessage: { caseKey: "", message: "" },
    sending: false,
  }),
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions("admin", [
      "getCase",
      "getCaseMessages",
      "getAttachmentData",
      "createCaseMessage",
    ]),
    async initData() {
      let requests = new Array();

      this.setLoading(true);
      this.skeleton = true;
      requests.push(this.getCase(this.caseKey));
      requests.push(this.getCaseMessages(this.caseKey));

      try {
        let results = await Promise.all(requests);
        this.currentCase = results[0].data.data;
        this.messages = results[1].data.data;
      } catch (error) {
      } finally {
        this.setLoading(false);
        this.skeleton = false;
      }
    },
    async sendMessage() {
      this.setLoading(true);
      this.sending = true;
      this.skeleton = true;
      let dto = { caseKey: this.caseKey, message: this.replyMessage };

      try {
        let result = await this.createCaseMessage(dto);
        let message = result.data.data;
        this.messages.unshift(message);
        this.replyDialog = false;
        this.replyMessage.message = "";

        this.$nextTick(() => this.$refs.replyForm.resetValidation());

      } catch (error) {
      } finally {
        this.setLoading(false);
        this.sending = false;
        this.skeleton = false;
      }
    },
    async downloadAttachment(message, attachment) {
      this.setLoading(true);
      let dto = {
        caseKey: this.caseKey,
        messageKey: message._key,
        attachmentKey: attachment._key,
      };

      try {
        let result = await this.getAttachmentData(dto);

        let file_name = `${attachment.fileName}`;
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$bus.$emit('errorMessage', 'Could not download attachment');
      } finally {
        this.setLoading(false);
      }
    },
    displayDate(timestamp) {
      let time = moment(timestamp);
      return time.format("DD.MM.YYYY");
    },
    displayTime(timestamp) {
      let time = moment(timestamp);
      return time.format("HH:mm");
    },
    getAvatarUrl(message) {
      if (!message.userKey || message.userKey.length === 0)
        return `/avatars/dummy.png`;

      return `/avatars/${message.userKey}.png`;
    },
    getUserAvatar(userKey) {
      return `/avatars/${userKey}.png`;
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("admin", ["clients", "cases", "self"]),
    messageRules() {
      return [(v) => !!v || this.$t("errorFieldRequired")];
    },
    caseTitle() {
      if (!this.currentCase) return "N/A";
      else return this.currentCase._key;
    },
    subjectName() {
      let meta = this.currentCase.metaData;

      if (!meta) return "N/A";

      if (meta.firstName) return `${meta.firstName} ${meta.lastName}`;
      else return `${meta.nickName}`;
    },
    messageCreated() {
      if (!this.currentCase || !this.currentCase.metaData) return "N/A";

      let meta = this.currentCase.metaData;

      if (meta.firstName) return this.$t("labelPersonalCaseCreated");
      else return this.$t("labelAnonymousCaseCreated");
    },
  },
  async mounted() {
    this.caseKey = this.$route.params.key;

    localStorage.setItem("caseLastViewed", JSON.stringify(this.caseKey));

    await this.initData();
  },
};
</script>

<style scoped>
.preserve {
  white-space: pre-line;
}

.message {
  display: block;
  font-size: 0.85rem;
  line-height: 1.25rem;
}

.case {
  border: 1px solid green;
}

.authro {
  font-size: 1rem;
}

.timestamp {
  font-size: 0.7rem;
}

.message-history {
  overflow: auto !important;
  padding: 10px;
  scrollbar-color: #e0e0e0 #fff;

  -ms-overflow-style: auto;
  scrollbar-base-color: #e0e0e0;
  scrollbar-face-color: #e0e0e0;
  scrollbar-3dlight-color: #fff;
  scrollbar-highlight-color: #fff;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: #e0e0e0;
  scrollbar-shadow-color: #fff;
  scrollbar-dark-shadow-color: #fff;

  display: flex;
  /* TODO: Revert once Firefox 81 is out for a while */
  /* Broken until Firefox 81*/
  /*flex-direction: column-reverse;*/
  /*height: 100%;*/
  flex-direction: column;
  max-height: 100%;
}

/*
.message-history::-webkit-scrollbar {
  width: 10px;
}
*/
.message-history::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px rgba(0, 69, 77, 1);
}

.message-history::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 25px rgba(224, 224, 224, 1);
}

.container {
  height: 95%;

  /* TODO: Remove once Firefox 81 is out for a while */
  /* Broken until Firefox 81*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
}
</style>